<template>
  <div class="container">
    <div
      class="left-menu"
      :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }"
    >
      <left-list :leftIndex="'6'" :isCollapse="isCollapse"></left-list>
    </div>
    <div
      class="right-content"
      :style="{ width: !isCollapse ? '88%' : '95.5%' }"
    >
      <div class="top-nav">
        <top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
      </div>
      <div class="left-list">
        <div class="left-list-title">
          <span>订单管理</span>
        </div>
        <div class="left-list-item left-list-active" @click="leftClick('0')">
          <span>订单列表</span>
        </div>
      </div>
      <div class="right-next">
        <div class="title">
          <span>订单详情</span>
        </div>
        <div class="order-progress">
          <div class="order-progress-item success">
            <div class="stateTxt">
              <span>商品创建时间</span>
            </div>
            <div class="time">{{ tableData[0].goods.created_at }}</div>
          </div>
          <div
            class="order-progress-item"
            :class="tableData[0].goods.net_status == 1 ? 'success' : ''"
          ></div>
          <div
            class="order-progress-item"
            :class="tableData[0].goods.net_status == 1 ? 'success' : ''"
          >
            <div class="stateTxt">
              <span>商品图片审核时间</span>
            </div>
            <div class="time">{{ tableData[0].goods.net_check_time }}</div>
          </div>
          <div
            class="order-progress-item"
            :class="tableData[0].come_time != 0 ? 'success' : ''"
          ></div>
          <div
            class="order-progress-item"
            :class="tableData[0].come_time != 0 ? 'success' : ''"
          >
            <div class="stateTxt">
              <span>寄件时间</span>
            </div>
            <div class="time">{{ tableData[0].come_time }}</div>
          </div>
          <!-- <div class="order-progress-item"></div>
          <div class="order-progress-item">
            <div class="stateTxt">
              <span>实物鉴定时间</span>
            </div>
            <div class="time">2021-08-01 00:00:00</div>
          </div> -->
        </div>
        <div class="title mt-20">
          <span>用户信息</span>
        </div>
        <div class="user-table">
          <el-table :data="tableData" style="width: 100%" border>
            <el-table-column
              prop="user.id"
              label="用户ID"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column label="用户头像" width="150" align="center">
              <template slot-scope="scope">
                <img
                  style="width: 60%"
                  :src="scope.row.user.avatarUrl"
                  alt=""
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="user.nickName"
              label="用户昵称"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
        <div class="title mt-20">
          <span>订单信息</span>
        </div>
        <div class="order-table">
          <el-table :data="tableData" style="width: 100%" border>
            <el-table-column
              prop="order_on"
              label="订单号"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="goods.id"
              label="商品ID"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="goods.category.name"
              label="商品分类"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="goods.name"
              label="商品名称"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="goods.remarks"
              label="商品备注"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              width="150"
              prop="state"
              label="订单状态"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.status == 0">待发货</span>
                <span v-if="scope.row.status == 2">运输中</span>
                <span v-if="scope.row.status == 3">鉴定中</span>
                <span v-if="scope.row.status == 4">鉴定成功</span>
                <span v-if="scope.row.status == 5">鉴定为假货</span>
                <span v-if="scope.row.status == 6">卖家拒绝售卖</span>
                <span v-if="scope.row.status == 7">已完成</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- <div class="img-compress" @click="unloadFile">
          <i class="el-icon-download" style="padding-right: 20px"></i>
          <span>一键下载图片压缩包</span>
        </div> -->
        <div class="title mt-20">
          <span>商品图片</span>
        </div>
        <div
          class="shopimg-list"
          v-for="(item, index) in tableData[0].goods.file_json"
          :key="index"
        >
          <p>{{ item.name }}</p>
          <img :src="$store.state.imaUrl + item.files" alt="" />
        </div>
        <div class="title mt-20">
          <span>图片已通过审核</span>
        </div>
        <div class="picture-review"></div>
        <div class="title mt-20">
          <span>交易类型</span>
        </div>
        <div class="mt-20">
          <span class="chushouBtn" v-if="tableData[0].sell_type == 0"
            >待客户选择售卖类型</span
          >
          <span class="chushouBtn" v-if="tableData[0].sell_type == 1"
            >售出</span
          >
          <span class="jimaiBtn" v-if="tableData[0].sell_type == 2">寄卖</span>
        </div>
        <div class="title mt-20">
          <span>实物审核</span>
        </div>
        <div class="mt-20 physical-examination">
          <p v-if="tableData[0].status == 4">已通过</p>
          <p v-if="tableData[0].status == 5">已驳回</p>
          <div class="reason">
            <div class="txt" v-if="tableData[0].status == 5">
              <span>驳回原因：{{ tableData[0].goods.remark }}</span>
            </div>
            <div class="txt" v-if="tableData[0].goods.is_flaw == 1">
              <span>瑕疵原因：</span>
            </div>
            <div class="reason-item" v-if="tableData[0].goods.is_flaw == 1">
              <div
                class="reason-item-list"
                v-for="(item, index) in tableData[0].goods.appraisal_file"
                :key="index"
              >
                <p>{{ item.name }}</p>
                <img :src="$store.state.imaUrl + item.files" alt="" />
              </div>
            </div>
          </div>
          <div>
            <span v-if="tableData[0].status != 5">成交价格：{{ tableData[0].price }} 元</span>
            <el-button
              type="primary"
              size="small"
              class="ml-40"
              v-if="tableData[0].status == 4"
              @click="paymentSubmit"
              >打款</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import leftList from "../../components/leftList.vue";
import topHeader from "../../components/header.vue"
export default {
  components: {
    leftList,
    topHeader
  },
  inject: ["reload"],
  data() {
    return {
      materialReject: "",
      imgReject: "",
      material: 1,
      consignment: "",
      sold: "",
      radio: "1",
      tableData: [],
      isCollapse: false,
      starttime: "",
      endtime: "",
      shopiing: "",
      state: "",
      inpuValue: "",
      region: "",
      pickerOptions: {
        //控制时间范围
        disabledDate(time) {
          return time.getTime() > Date.now() - 24 * 60 * 1000;
        },
      },
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    paymentSubmit() {
      this.$confirm("确定打款吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$post("payment/balance", {
            id: this.$route.query.id,
          }).then((res) => {
            if (res.data.status == 200) {
              this.$notify({
                title: "提示",
                message: "操作成功！",
                type: "success",
              });
              this.$router.push("/order");
            } else {
              this.$notify.error({
                title: "提示",
                message: res.data.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消打款",
          });
        });
    },
    unloadFile() {
      if (this.tableData[0].goods.file_json == null) {
        this.$notify.error({
          title: "提示",
          message: "该商品未上传图片",
        });
      } else {
        this.$post("get/zip", {
          id: this.$route.query.id,
        }).then((res) => {
          window.location.href = this.$store.state.imaUrl + res.data.data;
          console.log(res);
        });
      }
    },
    getInfo() {
      this.$get("get/one_order", {
        id: this.$route.query.id,
      }).then((res) => {
        this.tableData = [];
        console.log(res.data.data.goods.file_json);
        if (res.data.data.goods.file_json != null) {
          res.data.data.goods.file_json = JSON.parse(
            res.data.data.goods.file_json
          );
        }
        if(res.data.data.goods.appraisal_file != null){
          res.data.data.goods.appraisal_file = JSON.parse(
            res.data.data.goods.appraisal_file
          );
        }
        this.tableData.push(res.data.data);
        console.log(this.tableData);
      });
    },
    remove() {
      if (this.flawList.length > 1) {
        var m = this.flawList.slice();
        m.pop();
        this.flawList = m;
      } else {
        this.$notify.error({
          title: "提示",
          message: "至少声明一条原因",
        });
      }
    },
    add() {
      this.flawList.push({
        inputValue: "",
        imageUrl: "",
      });
    },
    uploadClick(index) {
      this.flawIndex = index;
    },
    handleAvatarSuccess(res, file) {
      this.flawList[this.flawIndex].imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    materialClick() {
      if (this.material == 2) {
        this.centerDialogVisible = true;
      }
    },
    menuitemClick(pushUrl) {
      // console.log(this.$route.path == pushUrl);
      if (this.$route.path == pushUrl) {
        this.reload();
      } else if (pushUrl) {
        this.$router.push(pushUrl);
      } else {
        this.$notify.error({
          title: "提示",
          message: "暂无页面",
        });
        this.reload();
      }
    },
    leftClick(index) {
      if (index == 0 && this.$route.path != "/order") {
        this.$router.push("/order");
      } else {
        this.reload();
      }
    },
    openMenu() {
      this.isCollapse = !this.isCollapse;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    handleEdit(index, row) {
      this.$router.push({
        name: "knowledgeedit",
        params: {
          row: row,
        },
      });
      //   console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
    //限制文本框只可输入数字
  },
};
</script>

<style lang="scss">
.reason {
  width: 500px;
  display: inline-block;
  .el-input {
    width: 200px;
  }
}
.operation {
  display: inline-block;
  vertical-align: top;
}
.right-content {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  vertical-align: top;
  display: inline-block;
  &::-webkit-scrollbar {
    display: none;
  }
  .physical-examination {
    padding: 0 100px;
    .reason {
      .txt {
        display: inline-block;
      }
      .reason-item {
        display: inline-block;
        vertical-align: top;
        p {
          margin: 0 0;
        }
        .reason-item-list {
          img {
            margin-top: 15px;
            width: 150px;
            height: 150px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
  .picture-review {
    // border: 1px solid;
    margin-top: 50px;
    padding: 0 100px;
    .left-region {
      display: inline-block;
      width: 700px;
      .el-input {
        width: 200px;
        margin: 0 20px;
      }
    }
    .right-region {
    }
  }
  .shopimg-list {
    margin-top: 20px;
    img {
      width: 150px;
      height: 150px;
    }
  }
  .user-table {
    display: inline-block;
    width: 600px;
    padding-top: 20px;
  }
  .order-table {
    display: inline-block;
    padding-top: 20px;
  }
  .img-compress {
    text-align: right;
    span,
    i {
      cursor: pointer;
    }
  }
  .order-progress {
    margin-top: 25px;
    &-item {
      //   border: 1px solid;
      display: inline-block;
      vertical-align: middle;
      width: calc(100% / 5);
      &:last-child {
        margin-right: 0px;
      }
      &:nth-child(2n) {
        position: relative;
        vertical-align: top;
        margin-top: 17px;
        background-color: rgb(139, 134, 134);
        height: 7px;
        border-radius: 100px;
        &::before {
          content: "";
          width: 12px;
          height: 12px;
          border: solid rgb(139, 134, 134);
          border-width: 0 5px 5px 0;
          transform: translate(-50%, -50%) rotate(-45deg);
          position: absolute;
          right: -10px;
          top: 50%;
        }
      }
      .stateTxt {
        text-align: center;
        span {
          display: inline-block;
          background-color: rgb(139, 134, 134);
          color: #fff;
          padding: 10px 0px;
          width: 200px;
          border-radius: 100px;
        }
      }
      .time {
        height: 100px;
        padding-top: 60px;
        text-align: center;
        box-sizing: border-box;
      }
    }
    .success {
      .stateTxt {
        span {
          background-color: #27b148;
        }
      }
      &:nth-child(2n) {
        position: relative;
        vertical-align: top;
        margin-top: 17px;
        background-color: #27b148;
        height: 7px;
        border-radius: 100px;
        &::before {
          content: "";
          width: 12px;
          height: 12px;
          border: solid #27b148;
          border-width: 0 5px 5px 0;
          transform: translate(-50%, -50%) rotate(-45deg);
          position: absolute;
          right: -10px;
          top: 50%;
        }
      }
    }
  }
  .date-search {
    margin-top: 30px;
    .el-button {
      margin-left: 40px;
      padding: 12px 55px;
    }
    .el-input {
      width: 200px;
    }
    .left,
    .right {
      display: inline-block;
    }
    .right {
      width: 1000px;
      vertical-align: top;
    }
  }
  .left-list {
    width: 10%;
    height: 93vh;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 14px;
    border-right: 20px solid #f2f2f2;
    display: inline-block;
    text-align: center;
    &::-webkit-scrollbar {
      display: none;
    }
    &-title {
      margin: 20px 0;
      font-size: 16px;
    }
    &-item {
      padding: 15px 0;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        color: #fff;
        background-color: #3291f8;
      }
    }
    &-active {
      color: #fff;
      background-color: #3291f8;
    }
  }
  .right-next {
    height: 93vh;
    width: 88.5%;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: 20px solid #f2f2f2;
    padding: 20px;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .title {
    color: #000;
    height: 10px;
    padding-left: 15px;
    line-height: 10px;
    border-left: 5px solid #80c4f8;
    margin-bottom: 10px;
  }

  .top-nav {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 15px;

    .left-icon {
      i {
        cursor: pointer;
        color: #3291f8;
        font-size: 30px;
      }

      .el-icon-refresh-right {
        margin-left: 30px;
      }
    }

    .right-user {
      font-size: 14px;

      span {
        vertical-align: middle;
        margin-right: 20px;
      }

      .colse-btn {
        cursor: pointer;
        vertical-align: middle;
        border: 1px solid #999;
        display: inline-block;
        padding: 5px 20px;
        border-radius: 50px;

        span {
          margin-right: 10px;
        }

        i {
          vertical-align: middle;
        }
      }
    }
  }
}

.left-menu {
  display: inline-block;

  .iconyonghuzu {
    font-size: 18px;
    margin: 0 5px;
  }
}

.el-menu {
  width: 100%;
  background-color: #20222a;
  height: 100vh;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  background-color: #20222a;
  color: #fff;
  height: 100vh;
  width: 100%;
}

.el-menu-item:focus,
.el-menu-item:hover {
  color: #000;
  background-color: #ecf5ff;
}

.el-menu-item {
  color: #fff;

  i {
    color: #3291f8;
  }
}

.el-menu-item.is-active {
  background-color: #ecf5ff;
  color: #20222a;
}

.menu-h5 {
  margin: 20px;
  text-align: center;
  font-size: 20px;
}

.menu-icon {
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  padding: 30px 22px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
